.container {
    display: flex;
    justify-content: center;
    width: 70%;
    margin-bottom: 50px;
}

@media only screen and (max-width: 770px) {
    .container {
        width: 100%;
        margin-bottom: 25px;
    }
}