body {
    background-color: rgb(250, 250, 250);
}

.container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.image {
    width: 256px;
    margin-top: 50px;

}

@media only screen and (max-width: 770px) {
    .container {
        margin: 0 10%;
    }

    .image {
        width: 70%;
    }
}