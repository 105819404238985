.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 50px 0px;
}

.card {
    margin: 2% 2% !important;
}

.vid {
    border-bottom: 10px solid rgba(0, 0, 0, 0.9)
}

.confirmed {
    border-bottom: 10px solid rgba(0, 0, 255, 0.7)
}

.active {
    border-bottom: 10px solid rgba(255, 204, 0, 0.7)
}

.recovered {
    border-bottom: 10px solid rgba(0, 255, 0, 0.7)
}

.deaths {
    border-bottom: 10px solid rgba(255, 0, 0, 0.7);
    /* margin: 3% 0 !important; */
}

@media only screen and (max-width: 770px) {
    .card {
        margin: 2% 0 !important;
    }
}